<template>
	<v-row>
		<v-dialog v-model="dialog.status" max-width="575" persistent>
			<CardDialog :dialog="dialog" />
		</v-dialog>
        <v-col cols="12" md="12"> 
			<v-card>
				<v-card-title>Novo Projeto</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row align="end">
						<v-col cols="12">
							<div class="text-body-1 font-weight-bold">Nome do Projeto</div>
							<v-text-field class="mb-n10" v-model="projeto.projeto" outlined dense></v-text-field>
						</v-col>
						<template>
							<v-col cols="12">
								<div class="text-body-1 font-weight-bold">Solicitante</div>
								<v-autocomplete
									class="mb-n10"
									v-model="projeto.idusuario"
									outlined
									dense
									:items="usuarios.lista"
									:loading="carregando"
									item-text="usuario"
									item-value="idusuario"
								></v-autocomplete>
							</v-col>
						</template>
                        <template>
                            <v-col class="mt-2" cols="12">
                                <v-textarea
                                    outlined
                                    dense
                                    label="Descrição do Projeto"
                                    rows="7"
                                    class="mb-n7"
                                    :loading="carregando"
                                    v-model="projeto.descricao"
                                ></v-textarea>
                            </v-col>
                        </template>
					</v-row>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-row>
						<v-col cols="12" class="mb-n3">
							<v-btn
								@click="abrirProjeto()"
								color="primary"
								block
								elevation="0"
								:disabled="carregando"
							>Cadastrar Projeto</v-btn>
						</v-col>
						<v-col cols="12">
							<v-btn
								class="text-decoration-underline"
								block
								elevation="0"
								color="primary"
								to="/projeto"
								text
								small
								exact
								:disabled="carregando"
							>Voltar</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-col>
		
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "ProjetoNovo",
	mixins: [mixinFilial],
	components: { CardDialog },
	data: () => ({
		dialog: { status: false },
		carregando: false,
		projeto: {
			projeto: "",
			descricao: "",
		},
		usuarios: { lista: [] },
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.map((v) => {
					const filial = {};
					filial.idfilial = v.idfilial;
					filial.filial = `${v.idfilial} - ${v.filial}`;
					return filial;
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		abrirProjeto() {
			if (
				this.projeto.projeto == "" ||
				this.projeto.descricao == ""
			) {
				this.dialog.status = true;
				this.dialog.title = "Erro";
				this.dialog.msg = "Um ou mais campos não foram preenchidos, preencha todos campos antes de prosseguir!";
				this.dialog.icon = "mdi-alert-circle-outline";
				this.dialog.iconColor = "red";
				return this.dialog;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}projeto/abrir`, {
					projeto: this.projeto.projeto,
					descricao: this.projeto.descricao,
					idusuariosolicitacao: this.projeto.idusuario || null,
				})
				.then((res) => {
					if (typeof res.data === "number") {
						return this.$router.push(`get/${res.data}`);
					}
					this.dialog.status = true;
					this.dialog.title = "Erro";
					this.dialog.msg = "Não foi possível abrir o projeto!";
					this.dialog.icon = "mdi-alert-circle-outline";
					this.dialog.iconColor = "red";
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
        // adicionarTarefas(){
        //     return axios
		// 		.post(`${this.backendUrl}projeto/tarefa/abrir`, {
		// 			tarefa: this.dtarefas[0].tarefa,
		// 			descricao: this.dtarefas[0].descricao,
		// 			idusuariosolicitacao: this.dtarefas[0].idusuariosolicitacao || null,
		// 		})
		// 		.then((res) => {
		// 			if (typeof res.data === "number") {
		// 				return this.$router.push(`get/${res.data}`);
		// 			}
		// 			this.dialog.status = true;
		// 			this.dialog.title = "Erro";
		// 			this.dialog.msg = "Não foi possível abrir o projeto!";
		// 			this.dialog.icon = "mdi-alert-circle-outline";
		// 			this.dialog.iconColor = "red";
		// 			this.carregando = false;
		// 		})
		// 		.catch(() => {
		// 			this.carregando = false;
		// 		});
        // },
		listarUsuarios() {
			this.usuarios.lista = [];
			return axios
				.post(`${this.backendUrl}usuario/listar`, {
					limit: 99999,
					offset: 0,
				})
				.then((res) => {
					if (res.data.lista != null) {
						this.usuarios = res.data;
					}
				});
		},
		async init() {
			// await this.listarDepartamentos();
			await this.listarUsuarios();
		},
    },
	
	created() {
		this.init();
	},
};
</script>